import React from 'react';
import { graphql } from 'gatsby';
import FAQTemplate from '@components/templates/FAQTemplate/FAQTemplate';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

const FAQS = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Frequently Asked Questions" />
      <GTMPageLoad
        {...{
          page_type: `faq`,
          page_taxonomy_field: `FAQ`,
          page_name: `Frequently Asked Questions`
        }}
      />
      <FAQTemplate />
    </Layout>
  );
};

export default FAQS;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
