import React from 'react';

import data from './faqs.json';

const faqSection = (question, text, list = [], sectionIndex) => (
  <section key={`faq-section-${sectionIndex}`} className="mars-padding-y--md">
    <h3 className="mars-gamma">{question}</h3>
    <p>{text}</p>
    {list.map((listItem, listIndex) => (
      <div key={`faq-section-list-${sectionIndex}-${listIndex}`}>
        <h4 className="mars-delta">{listItem.title}</h4>
        <ul>
          {listItem.items.map((item, itemIndex) => (
            <li
              key={`faq-section-list-item-${sectionIndex}-${listIndex}-${itemIndex}`}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    ))}
  </section>
);

const FAQTemplate = () => {
  return (
    <div className="mars-container mars-padding-y--xl">
      <h1 className="mars-alpha mars-text--center">FAQs</h1>
      <div className="mars-grid">
        <div className="mars-body mars-grid-col-3"></div>
        <div className="mars-body mars-grid-col-6">
          {data.questions.map(({ question, answer, list }, sectionIndex) =>
            faqSection(question, answer, list, sectionIndex)
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQTemplate;
